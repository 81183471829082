import React, { useState } from 'react';
import axios from 'axios';
import { MortgageResponse, FractionalMortgageTerm } from './types';
import { env } from '../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type MortgageFormProps = {
    onMortgageData: (data: MortgageResponse) => void;
};

function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-indexed
    const day = today.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`; // Format: YYYY-MM-DD
}
const MortgageForm: React.FC<MortgageFormProps> = ({ onMortgageData }) => {
    const [salary, setSalary] = useState('');
    const [loan, setLoan] = useState('');
    const [yearlyRate, setYearlyRate] = useState('4.21');
    const [years, setYears] = useState('30');
    const [propertyValue, setPropertyValue] = useState('');
    const [propertyGrowthRate, setPropertyGrowthRate] = useState('0');
    const [initialDeductibles, setInitialDeductibles] = useState('0');
    const [startDate, setStartDate] = useState(getCurrentDate());

    const [terms, setTerms] = useState<FractionalMortgageTerm[]>([]);
    const [newBuilding, setNewBuilding] = useState<boolean>(false);
    const [monthOffset, setMonthOffset] = useState('');
    const [termPercentage, setTermPercentage] = useState('');

    const [errors, setErrors] = useState({
        salary: '',
        loan: '',
        yearlyRate: '',
        years: '',
        propertyValue: '',
        propertyGrowthRate: '',
        initialDeductibles: '',
        term: '',
        terms: '',
    });

    const addTerm = () => {
        const offset = parseInt(monthOffset);
        const percentage = parseFloat(termPercentage);
        if (offset >= 0 && percentage > 0 && percentage < 100) {
            setTerms(prev => [
                ...prev,
                { month_offset: offset, fraction: percentage / 100 },
            ]);
            setMonthOffset('');
            setTermPercentage('');
            setErrors(prev => ({ ...prev, term: '' }));
        } else {
            setErrors(prev => ({
                ...prev,
                term: '% Must be larger than 0 and smaller than 100. Months must be at least 0.',
            }));
            // alert('Invalid input for terms. Please check your values.');
        }
    };

    const removeTerm = (index: number) => {
        setTerms(prev => prev.filter((_, i) => i !== index));
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            salary: '',
            loan: '',
            yearlyRate: '',
            years: '',
            propertyValue: '',
            propertyGrowthRate: '',
            initialDeductibles: '',
            term: '',
            terms: '',
        };

        if (!salary || +salary < 0) {
            newErrors.salary = 'Salary must be at least 0';
            isValid = false;
        }
        if (!loan || +loan < 0) {
            newErrors.loan = 'Loan amount must be at least 0';
            isValid = false;
        }
        if (!yearlyRate || +yearlyRate < 0) {
            newErrors.yearlyRate = 'Interest rate must be at least 0';
            isValid = false;
        }
        if (!years || +years < 0) {
            newErrors.years = 'Number of years must be at least 0';
            isValid = false;
        }
        if (!propertyValue || +propertyValue < 0) {
            newErrors.propertyValue = 'Property value must be at least 0';
            isValid = false;
        }
        if (!propertyGrowthRate || +propertyGrowthRate < 0) {
            newErrors.propertyGrowthRate = 'Growth rate must be at least 0';
            isValid = false;
        }

        if (!initialDeductibles || +initialDeductibles < 0) {
            newErrors.initialDeductibles =
                'One-off deductibles must be at least 0';
            isValid = false;
        }

        if (newBuilding) {
            const totalFraction = terms.reduce(
                (acc, term) => acc + term.fraction,
                0,
            );
            const errorMargin = 0.001;
            if (Math.abs(totalFraction - 1) > errorMargin) {
                newErrors.terms = `Percentages must sum up to 100%. Got ${(totalFraction * 100).toFixed(2)}%`;
                isValid = false;
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    console.log(`date: ${startDate}`);
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        console.log(`submitting`);
        if (validateForm()) {
            try {
                const response = await axios.post(env.MORTGAGE_API_URL, {
                    salary,
                    loan,
                    yearly_rate: +yearlyRate / 100,
                    years,
                    property_value: propertyValue,
                    property_yearly_growth_rate: +propertyGrowthRate / 100,
                    initial_deductibles: +initialDeductibles,
                    start_date: startDate,
                    terms: newBuilding && terms.length > 0 ? terms : undefined,
                });
                onMortgageData(response.data);
            } catch (error) {
                console.error('There was an error!', error);
            }
        }
    };

    return (
        <>
            <style>
                {`
                    .form-container {
                        max-width: 500px;
                        // margin: auto; // For centering
                        padding: 20px;
                        margin: 0 20px;  // Adds 20px margin to both sides
                        // margin-left: 20px
                    }
                    .form-group {
                        margin-bottom: 20px;
                    }
                    .form-group label {
                        display: block;
                        margin-bottom: 5px;
                        font-weight: bold;
                    }
                    .form-group input, .form-group select, .form-group textarea {
                        width: 100%;
                        padding: 8px;
                        box-sizing: border-box;
                        font-size: 16px; /* Set font size to 16px to prevent zooming on focus */
                    }
                    .form-group .checkbox input {
                        width: auto;
                    }
                    
                    input {
                        // width:400px;
                        border-radius: 5px;
                        border: 1px solid silver;  /* <-- Override default border */
                    }
                                        
                    button {
                        padding: 10px 20px;
                        background-color: #7cb093;
                        color: white;
                        border: none;
                        border-radius: 5px;
                        cursor: pointer;
                        width: 100%;
                        font-size: 16px;
                    }
                    button:hover {
                        background-color: #0056b3;
                    }
                    
                    
                    .terms-container {
                        margin-top: 20px;
                        margin-bottom: 20px;
                        padding: 15px;
                        background-color: #ffffff;
                        border-radius: 8px;
                        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                    }
                    
                    .term-input {
                        width: 45%;
                        padding: 8px;
                        margin: 5px;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                    }
                    
                                        
                    .add-term {
                        padding: 10px 20px;
                        margin-left: 5px;
                        background-color: #007bff;
                        color: white;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        display: block;
                        width: 120px;
                        margin-top: 10px;
                    }
                    
                    .add-term:hover {
                        background-color: #0056b3;
                    }
                    
                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin-top: 10px;
                    }
                    
                    li {
                        padding: 10px;
                        background-color: #f9f9f9;
                        border-radius: 4px;
                        margin-bottom: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    
                    .term-details {
                        display: flex;
                        flex-direction: column; /* Align children vertically */
                    }
                    
                    .term-months, .term-fraction {
                        margin-bottom: 5px; /* Adds spacing between the lines */
                    }
                    
                    .highlight {
                        font-weight: bold; /* Makes the font bold */
                        color: #007bff; /* Blue color for emphasis */
                    }

                    
                    .delete-term {
                        padding: 5px 10px;
                        background-color: #dc3545;
                        width: 40px;
                        color: white;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                    
                    .delete-term:hover {
                        background-color: #c82333;
                    }
                    
                    .toggle-container {
                        margin: 20px 0;
                    }
                    
                    .toggle-container label {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    
                    .toggle-container input[type="checkbox"] {
                        margin-right: 10px;
                    }
                    
                    .react-datepicker-wrapper,
                    .react-datepicker__input-container input {
                        width: 100%; /* Full width */
                      // padding: 0.375rem 0.75rem; /* Standard padding */
                      // border: 1px solid #ced4da; /* Standard border */
                      // border-radius: 0.25rem; /* Rounded borders */
                      font-size: 16px; /* Legible text */
                    }


                    .error {
                        color: red;
                        font-size: 0.8em;
                        margin-top: 5px;
                    }
                `}
            </style>
            <div className="form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="salary">Yearly salary (gross):</label>
                        <input
                            id="salary"
                            type="number"
                            value={salary}
                            onChange={e => setSalary(e.target.value)}
                            placeholder="Salary"
                        />
                        {errors.salary && (
                            <div className="error">{errors.salary}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="loan">Loan Amount:</label>
                        <input
                            id="loan"
                            type="number"
                            value={loan}
                            onChange={e => setLoan(e.target.value)}
                            placeholder="Loan Amount"
                        />
                        {errors.loan && (
                            <div className="error">{errors.loan}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="yearlyRate">
                            Yearly Interest Rate (in %, e.g. 4.21):
                        </label>
                        <input
                            id="yearlyRate"
                            type="number"
                            value={yearlyRate}
                            onChange={e => setYearlyRate(e.target.value)}
                            placeholder="Yearly Interest Rate"
                        />
                        {errors.yearlyRate && (
                            <div className="error">{errors.yearlyRate}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="years">Years:</label>
                        <input
                            id="years"
                            type="number"
                            value={years}
                            onChange={e => setYears(e.target.value)}
                            placeholder="Years"
                        />
                        {errors.years && (
                            <div className="error">{errors.years}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="propertyValue">Property Value:</label>
                        <input
                            id="propertyValue"
                            type="number"
                            value={propertyValue}
                            onChange={e => setPropertyValue(e.target.value)}
                            placeholder="Property Value"
                        />
                        {errors.propertyValue && (
                            <div className="error">{errors.propertyValue}</div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="propertyGrowthRate">
                            Property Yearly Growth Rate (in %):
                        </label>
                        <input
                            id="propertyGrowthRate"
                            type="number"
                            value={propertyGrowthRate}
                            onChange={e =>
                                setPropertyGrowthRate(e.target.value)
                            }
                            placeholder="Property Yearly Growth Rate"
                        />
                        {errors.propertyGrowthRate && (
                            <div className="error">
                                {errors.propertyGrowthRate}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="initialDeductibles">
                            One-off deductible amount
                        </label>
                        <input
                            id="initialDeductibles"
                            type="number"
                            value={initialDeductibles}
                            onChange={e =>
                                setInitialDeductibles(e.target.value)
                            }
                            placeholder="One-off deductible amount"
                        />
                        {errors.initialDeductibles && (
                            <div className="error">
                                {errors.initialDeductibles}
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="startDate">Start Date:</label>
                        {/*<input*/}
                        {/*    id="startDate"*/}
                        {/*    type="date"*/}
                        {/*    value={startDate}*/}
                        {/*    min={'2023-01-01'}*/}
                        {/*    onChange={e => setStartDate(e.target.value)}*/}
                        {/*    placeholder="Start Date"*/}
                        {/*/>*/}
                        <DatePicker
                            // showIcon
                            dateFormat="dd / MM / yyyy"
                            toggleCalendarOnIconClick={true}
                            minDate={new Date('2023-01-01')}
                            selected={startDate ? new Date(startDate) : null} // Convert back to Date object
                            onChange={(date: Date) =>
                                setStartDate(date.toISOString().split('T')[0])
                            }
                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                            onFocus={e => (e.target.readOnly = true)} // Avoids mobile keyboard popping up
                        />
                    </div>

                    <div className="toggle-container">
                        <label>
                            <input
                                type="checkbox"
                                checked={newBuilding}
                                onChange={() => {
                                    setNewBuilding(!newBuilding);
                                }}
                            />
                            Add Building Fund (e.g. for Nieuwbouw)
                        </label>
                    </div>

                    {newBuilding && (
                        <div className="terms-container">
                            <div className="form-group">
                                <label
                                    htmlFor="monthOffset"
                                    style={{
                                        marginBottom: '5px',
                                        color: '#333',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Months after building starts:
                                </label>
                                <input
                                    id="monthOffset"
                                    type="number"
                                    value={monthOffset}
                                    onChange={e =>
                                        setMonthOffset(e.target.value)
                                    }
                                    className="term-input"
                                />
                            </div>
                            <div className="form-group">
                                <label
                                    htmlFor="termPercentage"
                                    style={{
                                        marginBottom: '5px',
                                        color: '#333',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    % Paid from the building fund:
                                </label>
                                <input
                                    id="termPercentage"
                                    type="number"
                                    step="0.01"
                                    value={termPercentage}
                                    onChange={e =>
                                        setTermPercentage(e.target.value)
                                    }
                                    className="term-input"
                                />
                            </div>
                            {errors.term && (
                                <div className="error">{errors.term}</div>
                            )}
                            <button
                                onClick={addTerm}
                                className="add-term"
                                type={'button'}
                            >
                                Add Term
                            </button>
                            <ul>
                                {terms.map((term, index) => (
                                    <li key={index}>
                                        <div className="term-details">
                                            <div className="term-months">
                                                {' '}
                                                <span className="highlight">
                                                    {term.month_offset}
                                                </span>{' '}
                                                month
                                                {+term.month_offset > 1
                                                    ? 's'
                                                    : ''}{' '}
                                                after building starts an
                                                additional{' '}
                                            </div>
                                            <div className="term-fraction">
                                                <span className="highlight">
                                                    {(
                                                        +term.fraction * 100
                                                    ).toFixed(2)}
                                                    %
                                                </span>{' '}
                                                is paid from the building fund
                                            </div>
                                        </div>

                                        <button
                                            onClick={() => removeTerm(index)}
                                            className="delete-term"
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                        {/*<button*/}
                                        {/*    onClick={() => removeTerm(index)}*/}
                                        {/*    className="delete-term"*/}
                                        {/*>*/}
                                        {/*    Delete*/}
                                        {/*</button>*/}
                                    </li>
                                ))}
                            </ul>

                            {errors.terms && (
                                <div className="error">{errors.terms}</div>
                            )}
                        </div>
                    )}

                    <button type="submit" style={{ marginBottom: '20px' }}>
                        Calculate
                    </button>
                </form>
            </div>
        </>
    );
};

export default MortgageForm;
