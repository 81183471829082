import React, { useState } from 'react';

interface CollapsibleSectionProps {
    title: string;
    children: React.ReactNode;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
    title,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(true);
    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div style={{ marginBottom: '20px' }}>
            <button
                style={{
                    maxWidth: '500px',
                    background: 'none',
                    color: 'inherit',
                    border: 'none',
                    // padding: '20px',
                    font: 'inherit',
                    cursor: 'pointer',
                    outline: 'inherit',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textAlign: 'left',
                }}
                onClick={toggleOpen}
            >
                {isOpen ? <span>&#9650;</span> : <span>&#9660;</span>}
                {/*<span*/}
                {/*    style={{*/}
                {/*        marginRight: '10px',*/}
                {/*        transform: isOpen ? 'rotate(45deg)' : '',*/}
                {/*        transition: 'transform 150ms ease',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    &#9654;*/}
                {/*</span>*/} {title}
            </button>
            {isOpen && (
                <div
                    style={{
                        padding: '20px',
                        paddingTop: '10px',
                        borderRadius: '10px',
                        maxWidth: '500px',
                        margin: '0 20px',
                        fontSize: '13px',
                        backgroundColor: 'rgba(124, 176, 147, 0.4)',
                        // backgroundColor: '#7cb093',
                        boxSizing: 'border-box',
                    }}
                >
                    {children}
                </div>
            )}
        </div>
    );
};
