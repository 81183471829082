import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    ChartOptions,
} from 'chart.js';

// Register the chart components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

interface Payment {
    date: string;
    credit: number;
    interest: number;
    principal: number;
}

interface Props {
    payments: Payment[];
}

const MortgageChart: React.FC<Props> = ({ payments }) => {
    const data = {
        labels: payments.map(payment => payment.date),
        datasets: [
            {
                label: 'Net Mortgage',
                data: payments.map(
                    payment =>
                        payment.interest + payment.principal - payment.credit,
                ),
                backgroundColor: 'rgba(124, 176, 147, 0.5)', // More transparent
                // backgroundColor: 'rgba(54, 162, 235, 0.5)', // More transparent
                // borderColor: 'rgba(54, 162, 235, 1)',
                borderColor: 'rgba(47, 104, 76, 1)',
                fill: 'origin', // Fill from the origin
                tension: 0.3,
                borderWidth: 1, // Decreased line width
                pointRadius: 1,
            },
            {
                label: 'Gross Mortgage',
                data: payments.map(
                    payment => payment.interest + payment.principal,
                ),
                backgroundColor: 'rgba(255, 99, 132, 0.2)', // Less transparent
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: '-1', // Fill to the dataset under this (Net Interest)
                tension: 0.3,
                borderWidth: 1, // Decreased line width
                pointRadius: 1,
            },
        ],
    };

    const options: ChartOptions<'line'> = {
        // Specify the type for line chart options
        responsive: true,
        maintainAspectRatio: false,
        // scales: {
        //     y: {
        //         beginAtZero: true,
        //     },
        // },
        plugins: {
            // legend: {
            //     display: true,
            // },
            // tooltip: {
            //     mode: 'index',
            //     intersect: false,
            // },
            title: {
                display: true,
                text: 'Net / Gross mortgage amount per month',
                font: {
                    size: 16,
                },
                color: '#666', // Optional: change title color
            },
        },
    };

    return (
        <div
            style={{
                height: '600px',
                maxWidth: '1200px',
                marginTop: '30px',
                marginLeft: '20px',
                marginRight: '20px',
            }}
        >
            <Line data={data} options={options} />
        </div>
    );
};

export default MortgageChart;
