import { CollapsibleSection } from './CollapsibleSection';

export function MortgageCalculatorIntro() {
    return (
        <CollapsibleSection title={'Introduction'}>
            <p>
                Welcome to the most accurate Mortgage Calculator for the Dutch
                (Netherlands) market.
                <br />
                <br />
            </p>
            Factors we take into account:
            <ul>
                <li>
                    Interest deduction (hypotheekrenteaftrek). Here we also
                    consider the tax rate adjustment (tariefsaanpassing) for
                    high earners. Your salary is therefore an important factor
                    to consider
                </li>
                <li>Property tax (eigenwoningforfeit)</li>
                <li>
                    Wet Hillen. In the past you did not have to pay property tax
                    on a property that was fully paid off. However, this benefit
                    is slowly being taken away each year, and disappears
                    entirely in 2048.
                </li>
                <li>
                    Building funds. For buildings that are yet to be built
                    (nieuwbouw), the mortgage is typically placed in a building
                    fund. The costs for the building will be paid from the fund
                    in terms. For the money that is still in the fund (and not
                    yet used for the building), you will receive interest.
                </li>
            </ul>
            What we currently do not take into account:
            <ul>
                <li>
                    Age. This could be of relevance due to AOW. You pay slightly
                    less income tax when you are past the retirement age, which
                    can have a small effect on the hypotheekrenteaftrek.
                </li>
                <li>
                    We currently only consider salary from a fulltime
                    employment, and therefore do not take into account e.g. ZZP
                    benefits / deductibles / etc.
                </li>
                <li>We currently only support an annuity mortgage.</li>
            </ul>
        </CollapsibleSection>
    );
}
