import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import { MortgageResponse } from './components/types';
import MortgageForm from './components/MortgageForm';
import MortgageChart from './components/MortgageChart';
import { MortgageCalculatorIntro } from './components/MortgageCalculatorIntro';

function App() {
    const [mortgageData, setMortgageData] = useState<MortgageResponse | null>(
        null,
    );
    const handleMortgageData = (data: MortgageResponse) => {
        console.log(`Data: ${JSON.stringify(data)}`);
        setMortgageData(data);
    };

    return (
        <Router>
            <div className="App">
                <h1 style={{ marginLeft: '20px' }}>
                    Dutch Mortgage Calculator
                </h1>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate replace to="/mortgage-calculator" />}
                    />
                    <Route
                        path="/mortgage-calculator"
                        element={
                            <>
                                <MortgageCalculatorIntro></MortgageCalculatorIntro>
                                <MortgageForm
                                    onMortgageData={handleMortgageData}
                                />
                                {mortgageData && (
                                    <MortgageChart
                                        payments={mortgageData.payments}
                                    />
                                )}
                            </>
                        }
                    />
                    {/* Additional routes can be added here */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
